import React from "react";
import Layout from "../components/layout/ca"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import ContactInfo from "../components/contact/contact-info"

const ContactePage = (props) => {
  const heroText = "Contacte"
  const seoDescription = "Ponent 20, 08739 Sant Pau d'Ordal, Barcelona, t: +34938993028, email: info@calperedelmaset.com"


  return (
    <Layout location= {props.location}>
      <SEO 
        title="Contacte Cal Pere del Maset"
        lang="ca"
        description={seoDescription}
      />
      <HeroImage 
        titleText=""
        image="contacte" 
      />
      <ContactInfo 
        contact={heroText}
      />
    </Layout>
  )
}

export default ContactePage
