import React from "react"
import styled from 'styled-components'
import { useIntl } from "react-intl"

const ContactInfo = (props) => {
  const intl = useIntl();
  return (
    <BlockTitle>
      <BlockRow>
        <h1> {props.contact} Cal Pere del Maset </h1>
      </BlockRow>
      <BlockRow>
        <Block>
          <ul>
            <li><b>{ intl.formatMessage({ id: 'direccioCarrer'})}</b></li>
            <li>{ intl.formatMessage({ id: 'direccioPoblacio'})}</li>
            <li><b>{ intl.formatMessage({ id: 'numTelefon'})}</b></li>
            <li>{ intl.formatMessage({ id: 'email'})}</li>
          </ul>
          <ul>
            <li><b>{ intl.formatMessage({ id: 'diesDinarOberts'})}</b></li>
            <li>{ intl.formatMessage({ id: 'horesDinarOberts'})}</li>
            <li><b>{ intl.formatMessage({ id: 'diesSoparOberts'})}</b></li>
            <li>{ intl.formatMessage({ id: 'horesSoparOberts'})}</li>
            <li><b>{ intl.formatMessage({ id: 'diaTancat'})}</b></li>
          </ul>
        </Block>
        <Block>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993.595144257748!2d1.7930513154256658!3d41.382879979264615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a47dab6481cb2b%3A0xeecef3ba41cc09ee!2sCal%20Pere%20del%20maset!5e0!3m2!1sen!2ses!4v1591386001407!5m2!1sen!2ses" width="320" height="250" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </Block>
      </BlockRow>
    </BlockTitle>
  )
}

const BlockTitle = styled.section`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 22px;
  line-height: 32px;
  font-weight: 300;
  margin: 50px 175px;
  padding-bottom: 80px;
  ul {
    list-style: none;
  }
  
  @media (max-width: 1050px){
  	margin: 50px 90px;
  }
  @media (max-width: 541px){
    margin: 10px 20px;
  }
`

const BlockRow = styled.div`
  display: flex;
  @media (max-width: 1050px){
    flex-direction: column;
  }
`

const Block = styled.div`
  margin: 50px 50px;
  @media (max-width: 1050px){
    margin: 25px 25px;
  }
  @media (max-width: 541px){
    margin: 5px 5px;
  }
`

export default ContactInfo;